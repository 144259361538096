<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/voucher`"
                title="Voucher"
                :columns="columns"
                routerpath="/accounting/addvoucher"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit ChequeReturn" : "Add ChequeReturn"
        }`,
        submitRouterPath: "/crm/chequereturn",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/chequereturn`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Voucher NO",
            name: "voucherno",
            value: "",
            type: "number",
            placeholder: "Enter Voucher Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque NO",
            name: "chequeno",
            value: "",
            type: "number",
            placeholder: "Enter Cheque Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client Name",
            name: "clientname",
            value: "",
            type: "text",
            placeholder: "Enter Client Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Company Name",
            name: "companyname",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "companyname",
            options: [],
            // required: {
            //   required,
            // },
          },
          {
            label: "Bank Name",
            name: "bankname",
            value: "",
            type: "text",
            placeholder: "Enter Bank Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Amount",
            name: "amount",
            value: "",
            type: "text",
            placeholder: "Enter Amount",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque Date",
            name: "chequedate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Cheque date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Reason",
            name: "reason",
            value: "",
            type: "text",
            placeholder: "Enter Reason",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client's Bank",
            name: "clientbank",
            value: "",
            type: "text",
            placeholder: "Enter Client's Bank",
            class: "col-md-6",
          },
        ],
      },
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Branch",
          field: "branch",
          type: "dropdown",
          url: "branch",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Branch",
          },
        },
        {
          label: "Narration",
          field: "narration",
          filterOptions: {
            enabled: true,
            placeholder: "Search Narration",
          },
        },
        {
          label: "Type",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "Search Type",
          },
        },
         {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Voucher") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
